/* src/SearchBar.css */

.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

.search-input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    /* padding: 10px 15px; */
    height: 2.4em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
    /* Set a fixed width or use min-width */
}

.search-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.search-button:disabled {
    background-color: #007bff;
    opacity: 0.7;
    cursor: not-allowed;
}